
  h1 {
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-size: 2em !important;
  font-weight: 700;
}
  h2 {
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-size: 1.5em !important;
  font-weight: 700;
}
  h3 {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-size: 1.17em !important;
  font-weight: 700;
}
  h4 {
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
}
  h5 {
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-size: 0.83em !important;
  font-weight: 700;
}
  h6 {
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-size: 0.67em !important;
  font-weight: 700;
}
*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
.container {
  width: 100%;
}
@media (min-width: 400px) {
  .container {
    max-width: 400px;
  }
}
.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
/* regular */
.regular12-20 {
  font-size: 12px;
  line-height: 1.25rem;
}
.\!regular12-20 {
  font-size: 12px;
  line-height: 1.25rem;
}
.regular14-22 {
  font-size: 14px;
  line-height: 22px;
}
.\!regular14-22 {
  font-size: 14px;
  line-height: 22px;
}
.regular16-24 {
  font-size: 16px;
  line-height: 1.5rem;
}
.regular20-28 {
  font-size: 20px;
  line-height: 1.75rem;
}
.regular24-32 {
  font-size: 24px;
  line-height: 2rem;
}
/* semibold */
.semibold12-20 {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25rem;
}
.semibold14-22 {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
.semibold16-24 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5rem;
}
.\!semibold16-24 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5rem;
}
.semibold20-28 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.75rem;
}
.invisible {
  visibility: hidden;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.bottom-0 {
  bottom: 0px;
}
.-top-1 {
  top: -0.25rem;
}
.left-5 {
  left: 1.25rem;
}
.top-0 {
  top: 0px;
}
.left-0 {
  left: 0px;
}
.bottom-2 {
  bottom: 0.5rem;
}
.top-\[46px\] {
  top: 46px;
}
.right-0 {
  right: 0px;
}
.top-10 {
  top: 2.5rem;
}
.z-10 {
  z-index: 10;
}
.z-\[999\] {
  z-index: 999;
}
.z-50 {
  z-index: 50;
}
.z-30 {
  z-index: 30;
}
.z-0 {
  z-index: 0;
}
.z-20 {
  z-index: 20;
}
.m-auto {
  margin: auto;
}
.m-\[6px\] {
  margin: 6px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.\!my-\[10px\] {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-\[12px\] {
  margin-top: 12px;
  margin-bottom: 12px;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-\[10px\] {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-\[16px\] {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mt-\[120px\] {
  margin-top: 120px;
}
.mb-4 {
  margin-bottom: 1rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.mt-\[-208px\] {
  margin-top: -208px;
}
.mt-\[-64px\] {
  margin-top: -64px;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.ml-\[20px\] {
  margin-left: 20px;
}
.-mt-3 {
  margin-top: -0.75rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mt-\[4px\] {
  margin-top: 4px;
}
.mr-\[5px\] {
  margin-right: 5px;
}
.mt-4 {
  margin-top: 1rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mt-\[30px\] {
  margin-top: 30px;
}
.mt-\[16px\] {
  margin-top: 16px;
}
.mt-\[22px\] {
  margin-top: 22px;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-\[140px\] {
  margin-top: 140px;
}
.mt-\[24px\] {
  margin-top: 24px;
}
.mt-\[12px\] {
  margin-top: 12px;
}
.mt-\[10px\] {
  margin-top: 10px;
}
.ml-4 {
  margin-left: 1rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-\[49\.6px\] {
  margin-bottom: 49.6px;
}
.mt-\[45px\] {
  margin-top: 45px;
}
.mb-\[16px\] {
  margin-bottom: 16px;
}
.ml-\[4px\] {
  margin-left: 4px;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.hidden {
  display: none;
}
.h-\[54px\] {
  height: 54px;
}
.h-\[46px\] {
  height: 46px;
}
.h-\[50px\] {
  height: 50px;
}
.h-6 {
  height: 1.5rem;
}
.h-10 {
  height: 2.5rem;
}
.h-\[82px\] {
  height: 82px;
}
.h-20 {
  height: 5rem;
}
.h-\[22px\] {
  height: 22px;
}
.h-12 {
  height: 3rem;
}
.h-8 {
  height: 2rem;
}
.h-\[200px\] {
  height: 200px;
}
.h-\[64px\] {
  height: 64px;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
.\!h-12 {
  height: 3rem !important;
}
.\!h-\[300px\] {
  height: 300px !important;
}
.h-screen {
  height: 100vh;
}
.h-\[100vh\] {
  height: 100vh;
}
.h-\[40px\] {
  height: 40px;
}
.\!h-full {
  height: 100% !important;
}
.\!h-\[calc\(100\%-68px\)\] {
  height: calc(100% - 68px) !important;
}
.h-\[30px\] {
  height: 30px;
}
.h-\[80vh\] {
  height: 80vh;
}
.h-\[48px\] {
  height: 48px;
}
.h-\[26px\] {
  height: 26px;
}
.h-\[100\%\] {
  height: 100%;
}
.h-\[120px\] {
  height: 120px;
}
.h-11 {
  height: 2.75rem;
}
.max-h-screen {
  max-height: 100vh;
}
.max-h-\[40px\] {
  max-height: 40px;
}
.max-h-\[500px\] {
  max-height: 500px;
}
.\!min-h-\[42px\] {
  min-height: 42px !important;
}
.min-h-\[48px\] {
  min-height: 48px;
}
.min-h-screen {
  min-height: 100vh;
}
.w-full {
  width: 100%;
}
.w-\[54px\] {
  width: 54px;
}
.w-6 {
  width: 1.5rem;
}
.w-\[80px\] {
  width: 80px;
}
.w-\[22px\] {
  width: 22px;
}
.w-\[300px\] {
  width: 300px;
}
.w-\[64px\] {
  width: 64px;
}
.w-auto {
  width: auto;
}
.w-\[250px\] {
  width: 250px;
}
.w-\[100\%\] {
  width: 100%;
}
.w-\[20\%\] {
  width: 20%;
}
.w-\[77\%\] {
  width: 77%;
}
.w-1\/2 {
  width: 50%;
}
.w-44 {
  width: 11rem;
}
.w-12 {
  width: 3rem;
}
.w-\[30px\] {
  width: 30px;
}
.w-\[40\%\] {
  width: 40%;
}
.w-\[48px\] {
  width: 48px;
}
.w-24 {
  width: 6rem;
}
.w-\[24px\] {
  width: 24px;
}
.w-\[70\%\] {
  width: 70%;
}
.w-\[80\%\] {
  width: 80%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-\[150px\] {
  width: 150px;
}
.w-\[100px\] {
  width: 100px;
}
.w-\[95\%\] {
  width: 95%;
}
.w-\[50\%\] {
  width: 50%;
}
.w-\[220px\] {
  width: 220px;
}
.w-\[120px\] {
  width: 120px;
}
.min-w-\[250px\] {
  min-width: 250px;
}
.min-w-\[80px\] {
  min-width: 80px;
}
.min-w-\[370px\] {
  min-width: 370px;
}
.max-w-\[600px\] {
  max-width: 600px;
}
.max-w-xs {
  max-width: 20rem;
}
.max-w-\[80px\] {
  max-width: 80px;
}
.max-w-full {
  max-width: 100%;
}
.max-w-\[48px\] {
  max-width: 48px;
}
.max-w-md {
  max-width: 28rem;
}
.max-w-\[375px\] {
  max-width: 375px;
}
.flex-none {
  flex: none;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.shrink-0 {
  flex-shrink: 0;
}
.grow-0 {
  flex-grow: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.\!cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default;
}
.resize {
  resize: both;
}
.list-disc {
  list-style-type: disc;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-items-stretch {
  justify-items: stretch;
}
.gap-\[1px\] {
  gap: 1px;
}
.gap-4 {
  gap: 1rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-x-2 {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.gap-y-4 {
  row-gap: 1rem;
}
.gap-y-2 {
  row-gap: 0.5rem;
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.divide-gray-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-divide-opacity));
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.justify-self-end {
  justify-self: end;
}
.overflow-hidden {
  overflow: hidden;
}
.\!scroll-smooth {
  scroll-behavior: smooth !important;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-pre-line {
  white-space: pre-line;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-\[8px\] {
  border-radius: 8px;
}
.border {
  border-width: 1px;
}
.border-4 {
  border-width: 4px;
}
.border-\[1px\] {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-t {
  border-top-width: 1px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.border-dashed {
  border-style: dashed;
}
.border-none {
  border-style: none;
}
.border-\[gray-5\] {
  border-color: gray-5;
}
.border-gray-5 {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}
.border-red-6 {
  --tw-border-opacity: 1;
  border-color: rgb(245 34 45 / var(--tw-border-opacity));
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}
.\!border-blue-6 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(24 144 255 / var(--tw-border-opacity)) !important;
}
.border-green-3 {
  --tw-border-opacity: 1;
  border-color: rgb(183 235 143 / var(--tw-border-opacity));
}
.border-red-9 {
  --tw-border-opacity: 1;
  border-color: rgb(130 0 20 / var(--tw-border-opacity));
}
.bg-gray-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-gray-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.bg-\[\#FF4D4F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 77 79 / var(--tw-bg-opacity));
}
.bg-gray-3 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.bg-gray-8 {
  --tw-bg-opacity: 1;
  background-color: rgb(89 89 89 / var(--tw-bg-opacity));
}
.bg-red-6 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 34 45 / var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(38 50 56 / var(--tw-bg-opacity));
}
.\!bg-\[\#001529\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 21 41 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#001529\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 21 41 / var(--tw-bg-opacity));
}
.bg-gray-9 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.\!bg-\[\#F5F5F5\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#FFFFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.\!bg-gray-3 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}
.bg-gray-6 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 191 191 / var(--tw-bg-opacity));
}
.bg-green-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 255 237 / var(--tw-bg-opacity));
}
.bg-red-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 240 / var(--tw-bg-opacity));
}
.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}
.fill-primary {
  fill: #263238;
}
.fill-gray-6 {
  fill: #BFBFBF;
}
.object-contain {
  -o-object-fit: contain;
     object-fit: contain;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.p-3 {
  padding: 0.75rem;
}
.\!p-3 {
  padding: 0.75rem !important;
}
.p-1 {
  padding: 0.25rem;
}
.p-0 {
  padding: 0px;
}
.p-\[8px\] {
  padding: 8px;
}
.\!p-0 {
  padding: 0px !important;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-\[2px\] {
  padding-left: 2px;
  padding-right: 2px;
}
.px-\[0\.9rem\] {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}
.py-\[0\.6rem\] {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.\!px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.\!py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}
.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.\!py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-\[1px\] {
  padding-top: 1px;
  padding-bottom: 1px;
}
.\!py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}
.pt-3 {
  padding-top: 0.75rem;
}
.\!pt-\[8px\] {
  padding-top: 8px !important;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pb-\[60px\] {
  padding-bottom: 60px;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-\[5px\] {
  padding-bottom: 5px;
}
.\!pb-2 {
  padding-bottom: 0.5rem !important;
}
.pt-\[3px\] {
  padding-top: 3px;
}
.pt-\[8px\] {
  padding-top: 8px;
}
.pb-\[8px\] {
  padding-bottom: 8px;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-12 {
  padding-top: 3rem;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-start {
  text-align: start;
}
.align-middle {
  vertical-align: middle;
}
.font-source {
  font-family: var(--font-source), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.text-\[10px\] {
  font-size: 10px;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-\[8px\] {
  font-size: 8px;
}
.text-\[20px\] {
  font-size: 20px;
}
.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}
.text-\[14px\] {
  font-size: 14px;
}
.text-\[15px\] {
  font-size: 15px;
}
.text-\[18px\] {
  font-size: 18px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-\[16px\] {
  font-size: 16px;
}
.text-\[32px\] {
  font-size: 32px;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.\!text-\[16px\] {
  font-size: 16px !important;
}
.\!text-\[20px\] {
  font-size: 20px !important;
}
.text-\[12px\] {
  font-size: 12px;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.leading-\[28px\] {
  line-height: 28px;
}
.leading-\[15px\] {
  line-height: 15px;
}
.tracking-wider {
  letter-spacing: 0.05em;
}
.text-gray-6 {
  --tw-text-opacity: 1;
  color: rgb(191 191 191 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-red-4 {
  --tw-text-opacity: 1;
  color: rgb(255 120 117 / var(--tw-text-opacity));
}
.text-gray-8 {
  --tw-text-opacity: 1;
  color: rgb(89 89 89 / var(--tw-text-opacity));
}
.text-gray-10 {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-gray-7 {
  --tw-text-opacity: 1;
  color: rgb(140 140 140 / var(--tw-text-opacity));
}
.text-primary {
  --tw-text-opacity: 1;
  color: rgb(38 50 56 / var(--tw-text-opacity));
}
.text-red-6 {
  --tw-text-opacity: 1;
  color: rgb(245 34 45 / var(--tw-text-opacity));
}
.text-gray-9 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}
.\!text-gray-8 {
  --tw-text-opacity: 1 !important;
  color: rgb(89 89 89 / var(--tw-text-opacity)) !important;
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-red-2 {
  --tw-text-opacity: 1;
  color: rgb(255 204 199 / var(--tw-text-opacity));
}
.text-red-5 {
  --tw-text-opacity: 1;
  color: rgb(255 77 79 / var(--tw-text-opacity));
}
.text-blue-6 {
  --tw-text-opacity: 1;
  color: rgb(24 144 255 / var(--tw-text-opacity));
}
.text-gray-1 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.\!text-\[\#ffffff\] {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-\[\#1E1B21\] {
  --tw-text-opacity: 1;
  color: rgb(30 27 33 / var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-\[\#BFBFBF\] {
  --tw-text-opacity: 1;
  color: rgb(191 191 191 / var(--tw-text-opacity));
}
.text-blue-5 {
  --tw-text-opacity: 1;
  color: rgb(64 169 255 / var(--tw-text-opacity));
}
.text-\[\#4141e9e3\] {
  color: #4141e9e3;
}
.text-blue-7 {
  --tw-text-opacity: 1;
  color: rgb(9 109 217 / var(--tw-text-opacity));
}
.text-green-6 {
  --tw-text-opacity: 1;
  color: rgb(82 196 26 / var(--tw-text-opacity));
}
.text-\[\#313133\] {
  --tw-text-opacity: 1;
  color: rgb(49 49 51 / var(--tw-text-opacity));
}
.text-\[\#000000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-red-7 {
  --tw-text-opacity: 1;
  color: rgb(207 19 34 / var(--tw-text-opacity));
}
.text-gray-5 {
  --tw-text-opacity: 1;
  color: rgb(217 217 217 / var(--tw-text-opacity));
}
.underline {
  text-decoration-line: underline;
}
.opacity-0 {
  opacity: 0;
}
.opacity-70 {
  opacity: 0.7;
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
  outline-style: solid;
}
.blur-sm {
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.brightness-50 {
  --tw-brightness: brightness(.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-300 {
  transition-duration: 300ms;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}


/*Custom Scrollbar Style*/
.scrollbar {
  max-height: 196px;
  background: none;
  overflow-y: auto;
  margin-right: 2px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-right: 2px;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  margin-right: 2px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #d9d9d9;
  margin-right: 2px;
}

.overflow-anywhere {
  overflow-wrap: anywhere;
}

.cs-message--incoming .cs-message__content {
  background-color: #ffffff !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: #263238 !important;
}

.cs-conversation-header,
.cs-conversation-header__user-name {
  background-color: white !important;
  border: none !important;
}

.cs-message__content {
  padding: 0 !important;
  scroll-behavior: smooth;
}

.cs-message__html-content {
  padding: 0.6rem 0.9rem;
}

.cs-message__html-content a {
  text-decoration: underline !important;
}

.cs-conversation__unread {
  border-radius: 3rem !important;
  top: 30px !important;
  right: 0 !important;
  padding: 1px 8px 1px 8px !important;
  background-color: #263238 !important;
}

.cs-message__content-wrapper {
  flex-direction: row !important;
}

.cs-message__header {
  font-size: 12px !important;
  padding-right: 2px;
  color: #9a989c !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center;
  /* height: 180px; */
}

.cs-message__footer {
  font-size: 12px !important;
  padding-left: 2px;
  color: #9a989c !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center;
}

.cs-conversation-header__avatar img {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.cs-avatar img {
  -o-object-fit: cover !important;
     object-fit: cover !important;
  content-visibility: auto !important;
}

.cs-conversation__content {
  margin-right: 0 !important;
}

.htmlEditor table {
  display: block;
  max-width: 375px;
  width: 320px !important;
  margin: auto;
  border-collapse: collapse;
  overflow-x: auto;
}

.htmlEditor table thead {
  font-weight: bold;
  color: black;
}
.htmlEditor td,
.htmlEditor th {
  border: 1px solid #ddd;
  padding: 8px;
}

.htmlEditor a {
  text-decoration: underline;
  color: #3182ce;
}

.htmlEditor ol {
  margin-left: 1px;
}
.htmlEditor ul {
  margin-left: 1px;
}
.htmlEditor li {
  margin-left: 15px;
}

.cropper-image {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:font-semibold:hover {
  font-weight: 600;
}

.hover\:text-red-6:hover {
  --tw-text-opacity: 1;
  color: rgb(245 34 45 / var(--tw-text-opacity));
}

.hover\:text-gray-10:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-blue-7:hover {
  --tw-text-opacity: 1;
  color: rgb(9 109 217 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-sm:hover {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:border-2:focus {
  border-width: 2px;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus-visible\:outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.group:hover .group-hover\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

@media (min-width: 375px) {
  .min-\[375px\]\:flex {
    display: flex;
  }
}

.\[\&\>svg\]\:h-full>svg {
  height: 100%;
}

.\[\&\>svg\]\:w-full>svg {
  width: 100%;
}

.\[\&\>li\]\:flex>li {
  display: flex;
}

.\[\&\>li\]\:h-8>li {
  height: 2rem;
}

.\[\&\>li\]\:w-fit>li {
  width: -moz-fit-content;
  width: fit-content;
}

.\[\&\>li\]\:min-w-\[8\]>li {
  min-width: 8;
}

.\[\&\>li\]\:items-center>li {
  align-items: center;
}

.\[\&\>li\]\:justify-center>li {
  justify-content: center;
}

.\[\&\>li\]\:rounded-sm>li {
  border-radius: 0.125rem;
}

.\[\&\>li\]\:border>li {
  border-width: 1px;
}

.\[\&\>li\]\:border-gray-6>li {
  --tw-border-opacity: 1;
  border-color: rgb(191 191 191 / var(--tw-border-opacity));
}

.\[\&\>li\]\:bg-gray-1>li {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.\[\&\>li\]\:p-3>li {
  padding: 0.75rem;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/90ce457ee8cec043-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe8a99a918c9dff4-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bb83722ca01b414e-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/af9d511c7a25f62f-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6b30462463a75ce7-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/84d4affa47fcabed-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0ac14a3c407fb3c4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c762c418d6be0c3e-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ce3f06ff8220f479-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/20fca0a84b06d374-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2dbc97c4c2289ed4-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/57c2f9c15684dfcb-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2c78b631c1580249-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/fc6fba7ce0876fef-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c9ac84301026d52c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2a4d1d8ebdb63f65-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/30d2173387678482-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e81100bd7094716d-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6cec856d45e4d440-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a8acc0e4b6e7a16f-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_Pro_ac89ad';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3cc61a2a1d48cb85-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_Pro_Fallback_ac89ad';src: local("Arial");ascent-override: 104.47%;descent-override: 28.98%;line-gap-override: 0.00%;size-adjust: 94.19%
}.__className_ac89ad {font-family: '__Source_Sans_Pro_ac89ad', '__Source_Sans_Pro_Fallback_ac89ad';font-style: normal
}.__variable_ac89ad {--font-source: '__Source_Sans_Pro_ac89ad', '__Source_Sans_Pro_Fallback_ac89ad'
}

