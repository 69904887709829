@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply mt-[0.67em] mb-[0.67em] ml-[0] mr-[0] !text-[2em] font-bold;
  }
  h2 {
    @apply mt-[0.83em] mb-[0.83em] ml-[0] mr-[0] !text-[1.5em] font-bold;
  }
  h3 {
    @apply mt-[1em] mb-[1em] ml-[0] mr-[0] !text-[1.17em] font-bold;
  }
  h4 {
    @apply mt-[1.33em] mb-[1.33em] ml-[0] mr-[0] font-bold;
  }
  h5 {
    @apply mt-[1.67em] mb-[1.67em] ml-[0] mr-[0] !text-[0.83em] font-bold;
  }
  h6 {
    @apply mt-[2.33em] mb-[2.33em] ml-[0] mr-[0] !text-[0.67em] font-bold;
  }
}

@layer components {
  .title {
    @apply text-[18px] font-bold leading-[25px] text-black;
  }
  .body1 {
    @apply text-[16px] leading-6 text-black;
  }
  .body2 {
    @apply text-[14px] leading-[19px] text-black;
  }
  /* regular */
  .regular12-20 {
    @apply text-[12px] leading-5;
  }
  .regular14-22 {
    @apply text-[14px] leading-[22px];
  }
  .regular16-24 {
    @apply text-[16px] leading-6;
  }
  .regular20-28 {
    @apply text-[20px] leading-7;
  }
  .regular24-32 {
    @apply text-[24px] leading-8;
  }
  .regular30-38 {
    @apply text-[30px] leading-[38px];
  }
  .regular38-46 {
    @apply text-[38px] leading-[46px];
  }
  .regular46-54 {
    @apply text-[46px] leading-[54px];
  }
  .regular56-64 {
    @apply text-[56px] leading-[64px];
  }
  /* semibold */
  .semibold12-20 {
    @apply text-[12px] font-semibold leading-5;
  }
  .semibold14-22 {
    @apply text-[14px] font-semibold leading-[22px];
  }
  .semibold16-24 {
    @apply text-[16px] font-semibold leading-6;
  }
  .semibold20-28 {
    @apply text-[20px] font-semibold leading-7;
  }
  .semibold24-32 {
    @apply text-[24px] font-semibold leading-8;
  }
  .semibold30-38 {
    @apply text-[30px] font-semibold leading-[38px];
  }
  .semibold38-46 {
    @apply text-[38px] font-semibold leading-[46px];
  }
  .semibold46-54 {
    @apply text-[46px] font-semibold leading-[54px];
  }
  .semibold56-64 {
    @apply text-[56px] font-semibold leading-[64px];
  }
}


/*Custom Scrollbar Style*/
.scrollbar {
  max-height: 196px;
  background: none;
  overflow-y: auto;
  margin-right: 2px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-right: 2px;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  margin-right: 2px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #d9d9d9;
  margin-right: 2px;
}

.overflow-anywhere {
  overflow-wrap: anywhere;
}

.cs-message--incoming .cs-message__content {
  background-color: #ffffff !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: theme('colors.primary') !important;
}

.cs-conversation-header,
.cs-conversation-header__user-name {
  background-color: white !important;
  border: none !important;
}

.cs-message__content {
  padding: 0 !important;
  scroll-behavior: smooth;
}

.cs-message__html-content {
  padding: 0.6rem 0.9rem;
}

.cs-message__html-content a {
  text-decoration: underline !important;
}

.cs-conversation__unread {
  border-radius: 3rem !important;
  top: 30px !important;
  right: 0 !important;
  padding: 1px 8px 1px 8px !important;
  background-color: theme('colors.primary') !important;
}

.cs-message__content-wrapper {
  flex-direction: row !important;
}

.cs-message__header {
  font-size: 12px !important;
  padding-right: 2px;
  color: #9a989c !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center;
  /* height: 180px; */
}

.cs-message__footer {
  font-size: 12px !important;
  padding-left: 2px;
  color: #9a989c !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center;
}

.cs-conversation-header__avatar img {
  object-fit: cover !important;
}

.cs-avatar img {
  object-fit: cover !important;
  content-visibility: auto !important;
}

.cs-conversation__content {
  margin-right: 0 !important;
}

.htmlEditor table {
  display: block;
  max-width: 375px;
  width: 320px !important;
  margin: auto;
  border-collapse: collapse;
  overflow-x: auto;
}

.htmlEditor table thead {
  font-weight: bold;
  color: black;
}
.htmlEditor td,
.htmlEditor th {
  border: 1px solid #ddd;
  padding: 8px;
}

.htmlEditor a {
  text-decoration: underline;
  color: #3182ce;
}

.htmlEditor ol {
  margin-left: 1px;
}
.htmlEditor ul {
  margin-left: 1px;
}
.htmlEditor li {
  margin-left: 15px;
}

.cropper-image {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
}
